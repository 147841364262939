import {keys, mapValues, some} from 'lodash';
import forEach from 'lodash/forEach';
import isNil from 'lodash/isNil';
import {
  CalculatorFormFields
} from '../../components/loan-applications/loan-application/steps/calculator/calculator.model';
import {DictionaryEntry} from '../../shared/model/dictionary.model';
import {LoanSimulationParams} from '../../shared/model/loan-simulation.model';
import {DictionaryHelper} from '../dictionary-helper';
import {ConfiguredFieldRecords} from './configured-fields-utils';

interface DictionaryEntries {
  advanceInterestApplication?: DictionaryEntry[];
  creationType?: DictionaryEntry[];
  paymentInterval?: DictionaryEntry[];
  uidApplication?: DictionaryEntry[];
}

export const getSimulationDataBasedOnFields =
  (fields: CalculatorFormFields, dictionaryEntries: DictionaryEntries, processId: number): LoanSimulationParams => {

    const {
      advanceInterestApplication,
      advanceInterestNo,
      creationType,
      dateGranted,
      overrideAmortizationAmount,
      diminishingAmortizationNumber,
      interestRate,
      paymentInterval,
      principal,
      totalAmortizationNumber,
      firstPaymentDate,
      interestCalculationParameter,
      uidAmortizationNumber,
      uidApplication
    } = mapValues(fields, 'value');

    const getTotalAmortizationNumber = (): number => {
      return typeof totalAmortizationNumber != 'string' ? totalAmortizationNumber : Number(totalAmortizationNumber);
    };

    return {
      advanceInterestNo,
      overrideAmortizationAmount,
      diminishingAmortizationNumber,
      firstPaymentDate,
      grantDate: dateGranted,
      interestRate,
      productTypeId: processId,
      principalAmount: principal,
      totalAmortizationNumber: getTotalAmortizationNumber(),
      creationType: getOptionalEntryCodeById(creationType, dictionaryEntries.creationType),
      paymentInterval: getOptionalEntryCodeById(paymentInterval, dictionaryEntries.paymentInterval),
      advanceInterestApplication:
        getOptionalEntryCodeById(advanceInterestApplication, dictionaryEntries.advanceInterestApplication),
      interestCalculationParameter,
      uidAmortizationNumber,
      uidApplication: getOptionalEntryCodeById(uidApplication, dictionaryEntries.uidApplication),
      simulatedFees: fields.simulation?.input.simulatedFees
    };
  };

export const updateConfiguredFieldsWithSavedData =
  (fields: ConfiguredFieldRecords, entries: DictionaryEntries, savedData: Record<string, unknown>): void => {
    forEach(savedData, (value, key): void => {

      // update values only for fields corresponding with saved data
      if (!fields[key] || value === undefined) {
        return;
      }

      if (isNil(value)) {
        fields[key].value = value;
        return;
      }

      const isDictionaryType = some(keys(entries), entryKey => entryKey === key);
      fields[key].value = isDictionaryType ? DictionaryHelper.getEntryIdByCode(value, entries[key]) : String(value);
    });
  };

function getOptionalEntryCodeById(id?: number | string, entries?: DictionaryEntry[]): string | undefined {
  return id && entries ? DictionaryHelper.getEntryCodeById(id, entries) : undefined;
}
