import React, {ReactElement} from 'react';
import {SimulatedFee} from '../../../../../../../../shared/model/loan-simulation.model';
import styles from './FeesOverrideTable.module.scss';
import {FeesOverrideTableBody} from './FeesOverrideTableBody';
import {FeesOverrideTableHead} from './FeesOverrideTableHead';

interface Props {
  fees: SimulatedFee[];
}

export const FeesOverrideTable = ({fees}: Props): ReactElement =>
  <div className={styles.container}>
    <FeesOverrideTableHead />
    <FeesOverrideTableBody fees={fees} />
  </div>;
