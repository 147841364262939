import {FieldType} from './field.model';

export interface CheckTO<T_PROOF_TYPE = string, T_TYPE = string> {
  automatic?: boolean;
  name?: string;
  orderNo: number;
  phaseId?: number;
  required: boolean;
  selfCare: boolean;
  proofType?: T_PROOF_TYPE;
  type?: T_TYPE;
  roleIds: number[];
  dictionaryId?: number;
  maxAge?: number;
  minAge?: number;
  maxIncome?: number;
  minIncome?: number;
  provinceIds?: number[];
  id?: number;
}

export type Check = CheckTO<FieldType, CheckType>;

export const fromCheckTO = (checkTO: CheckTO): Check => ({
  ...checkTO,
  proofType: checkTO.proofType ? FieldType[checkTO.proofType] : undefined,
  type: checkTO.type as CheckType
});

export interface DraggableCheckItem extends Check {
  key: string;
}

export enum CheckType {
  AGE = 'AgeCheckView',
  INCOME = 'IncomeCheckView',
  MANUAL = 'ManualCheckView',
  PROVINCE = 'ProvinceCheckView',
}
