import isNil from 'lodash/isNil';
import some from 'lodash/some';
import {UserData} from '../../../../../../security-context.model';
import {Permission} from '../../../../../../shared/model/permission.model';
import {isSysAdminOrManager} from '../../../../../../utils/permissions-utils';
import {FieldDisablementReason} from '../configured-fields/field-disablement-reason.model';

export const checkPermissionForUser =
  (permittedRolesIds?: number[], userData?: UserData): FieldDisablementReason | undefined => {

    if (isNil(userData)) {
      return FieldDisablementReason.NO_PERMISSIONS_TO_EDIT;
    }

    if (isSysAdminOrManager(userData)) {
      return undefined;
    }

    return some(permittedRolesIds, role => some(userData.roleIds, userRole => userRole === role))
      ? undefined  // no reason to disable field if user has any permitted role
      : FieldDisablementReason.NO_PERMISSIONS_TO_EDIT;
  };


