import {NxButton, NxButtonVariant} from '@nextbank/ui-components';
import {isEmpty} from 'lodash';
import React, {ReactElement, useContext, useMemo, useState} from 'react';
import {SecurityContext} from '../../../../../../../../App';
import {ReactComponent as CheckedIcon} from '../../../../../../../../assets/images/icon-checked.svg';
import {EXECUTIONS} from '../../../../../../../../constants/api-urls';
import usePost from '../../../../../../../../shared/hooks/use-post.hook';
import {NumberedApprover} from '../../../../../../../../shared/model/phase.model';
import {TransHelper} from '../../../../../../../../utils/trans-helper';
import {AppSnackbarContext} from '../../../../../../../shared/app-snackbar-provider/AppSnackbarProvider';
import {ApprovalExecution, ApprovalExecutionInput} from '../../../../../loan-application.model';
import {LoanApplicationContext} from '../../../../../LoanApplication';
import {getAvailableApprovalOptions} from '../approval-utils';
import styles from './ApprovalExecutionBar.module.scss';
import {ApprovalExecutionPopup} from './popup/ApprovalExecutionPopup';

const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_APPLICATIONS.STEP_APPROVAL');

interface Props {
  applicationId: string;
  approvers: NumberedApprover[];
  approvalExecutions: ApprovalExecution[];
}

const getApproveUrl = (applicationId: string): string => `${EXECUTIONS}/${applicationId}/approve`;
const getRejectUrl = (applicationId: string): string => `${EXECUTIONS}/${applicationId}/reject`;

export const ApprovalExecutionBar = ({applicationId, approvers, approvalExecutions}: Props): ReactElement => {

  const {userData} = useContext(SecurityContext);
  const {refreshApplication, roles} = useContext(LoanApplicationContext);
  const {showErrorMessage} = useContext(AppSnackbarContext);

  const availableApprovalOptions = useMemo(() =>
    getAvailableApprovalOptions(approvalExecutions, approvers, userData, roles),
  [approvers, approvalExecutions]);

  const noApprovalPermissions = isEmpty(availableApprovalOptions);

  const approveStep = usePost<void, ApprovalExecutionInput>(getApproveUrl(applicationId));
  const rejectStep = usePost<void, ApprovalExecutionInput>(getRejectUrl(applicationId));

  const [rejectPopupOpen, setRejectPopupOpen] = useState(false);
  const [approvePopupOpen, setApprovePopupOpen] = useState(false);

  const toggleApprovePopup = (): void => setApprovePopupOpen(!approvePopupOpen);
  const toggleRejectPopup = (): void => setRejectPopupOpen(!rejectPopupOpen);

  const onApprove = (approvalExecution: ApprovalExecutionInput): void => {
    approveStep(approvalExecution)
      .then(refreshApplication)
      .then(() => toggleApprovePopup())
      .catch(error => showErrorMessage(error.message));
  };

  const onReject = (approvalExecution: ApprovalExecutionInput): void => {
    rejectStep(approvalExecution)
      .then(refreshApplication)
      .then(() => toggleRejectPopup())
      .catch(error => showErrorMessage(error.message));
  };

  return (
    <>
      <NxButton onClick={toggleRejectPopup}
                variant={NxButtonVariant.REJECT}
                className={styles.button}
                disabled={noApprovalPermissions}>
        <PrefixTrans>REJECT</PrefixTrans>
      </NxButton>
      <NxButton onClick={toggleApprovePopup}
                startIcon={<CheckedIcon />}
                className={styles.button}
                disabled={noApprovalPermissions}>
        <PrefixTrans>APPROVE</PrefixTrans>
      </NxButton>
      <ApprovalExecutionPopup availableApprovalOptions={availableApprovalOptions}
                              onCancel={toggleRejectPopup}
                              open={rejectPopupOpen}
                              onConfirm={onReject}
                              rejectPopupVariant />
      <ApprovalExecutionPopup availableApprovalOptions={availableApprovalOptions}
                              onCancel={toggleApprovePopup}
                              open={approvePopupOpen}
                              onConfirm={onApprove} />
    </>
  );
};
