import {NxLoader} from '@nextbank/ui-components';
import isNil from 'lodash/isNil';
import React, {createContext, useEffect, useState} from 'react';
import {SESSION_URL} from './constants/api-urls';
import MainRouting from './MainRouting';
import {SecurityContextType, UserData} from './security-context.model';
import useGet from './shared/hooks/use-get.hook';
import './styles/styles.scss';

export const SecurityContext = createContext<SecurityContextType>({
  userData: undefined,
  setUserData: () => null,
  isLogged: undefined,
  setIsLogged: () => null,
  logoutReason: undefined,
  setLogoutReason: () => null
});

const App = (): React.ReactElement => {

  const [userData, setUserData] = useState<UserData>();
  const [isLogged, setIsLogged] = useState<boolean>();
  const [logoutReason, setLogoutReason] = useState<string>();
  const checkSession = useGet<UserData>(SESSION_URL);

  useEffect(() => {
    checkSession()
      .then(userData => {
        if (!userData.additionalFactorRequired) {
          // If not second factor required and user data present then can be considered as logged in
          setUserData(userData);
          setIsLogged(true);
          return;
        }
        setIsLogged(false);
        setUserData(undefined);
      })
      .catch(() => {
        setUserData(undefined);
        setIsLogged(false);
      });
  }, []);

  return (
    <SecurityContext.Provider value={{isLogged, setIsLogged, logoutReason, setLogoutReason, userData, setUserData}}>
      {
        isNil(isLogged) ? <NxLoader /> : <MainRouting />
      }
    </SecurityContext.Provider>
  );
};

export default App;
