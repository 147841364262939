import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import React, {ReactElement} from 'react';
import {SimulatedFee} from '../../../../../../../shared/model/loan-simulation.model';
import {TransHelper} from '../../../../../../../utils/trans-helper';
import EmptyState from '../../../../../../shared/empty-state/EmptyState';
import {FeesOverrideTable} from './table/FeesOverrideTable';

interface Props {
  fees?: SimulatedFee[];
}

export const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_CONFIGURATIONS.LOAN_APPLICATION_DATA');

export const FeesOverride = ({fees}: Props): ReactElement =>
  isNil(fees) || isEmpty(fees)
    ? <EmptyState instruction={<PrefixTrans>FEES_OVERRIDE.EMPTY_STATE</PrefixTrans>} />
    : <FeesOverrideTable fees={fees} />;
