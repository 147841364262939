import {NxSelectOption} from '@nextbank/ui-components';
import {truncate} from 'lodash';
import isEmpty from 'lodash/isEmpty';
import {UserData} from '../../../../../../../security-context.model';
import {Branch, Role} from '../../../../../../../shared/model/management.model';
import {ApprovalRule, Approver, NumberedApprover} from '../../../../../../../shared/model/phase.model';
import {isSysAdminOrManager} from '../../../../../../../utils/permissions-utils';
import {TransHelper} from '../../../../../../../utils/trans-helper';
import {ApprovalExecution} from '../../../../loan-application.model';

export const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_APPLICATIONS.STEP_APPROVAL');

export const getNamesChain = (namedEntities: (Role | Branch)[]): string =>
  namedEntities
    .map(({name}) => name)
    .reduce((firstName, secondName) => {
      if (firstName == '') {
        return secondName;
      }
      return `${firstName} / ${secondName}`;
    }, '');

const MAX_LENGTH_OF_ROLES_LABEL = 55;

export const getAvailableApprovalOptions =
  (approvalExecutions: ApprovalExecution[], approvers: NumberedApprover[], userData?: UserData, roles: Role[] = [])
    : NxSelectOption<number>[] => {

    if (!userData) {
      return [];
    }

    if (isEmpty(roles)) {
      throw Error('No roles are available for this user');
    }

    return approvers
      .filter(approver => hasPermissionToExecute(approver, approvalExecutions, userData))
      .map(approver => {

        const concatenatedRoleNames = getNamesChain(roles.filter(({id}) => approver.roleIds.includes(id)));
        const label = `#${approver.ordinalNumber} ${concatenatedRoleNames}`;

        return {
          value: approver.id!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
          label: truncate(label, {length: MAX_LENGTH_OF_ROLES_LABEL})
        };
      });
  };

const hasPermissionToExecute = (approver: Approver, executions: ApprovalExecution[], userData: UserData): boolean => {

  const {branchIds, roleIds, id} = approver;
  const isSystemAdminOrManager = isSysAdminOrManager(userData);
  const isOnApproverBranch = isEmpty(branchIds) || branchIds.includes(userData.branchId);
  const hasApproverRole = isEmpty(roleIds) || userData.roleIds.some(roleId => roleIds.includes(roleId));
  const approvalAlreadyExecuted = executions.some(executedApproval => executedApproval.approverId === id);

  return isSystemAdminOrManager || (isOnApproverBranch && hasApproverRole && !approvalAlreadyExecuted);
};

export const getAvailableApprovers = (approvalRules: ApprovalRule[], principalAmount: number): NumberedApprover[] =>
  approvalRules
    .filter(({minAmount, maxAmount}) =>
      (!minAmount || minAmount <= principalAmount) && (!maxAmount || maxAmount >= principalAmount))
    .flatMap(({approvers}) => approvers)
    .map((approver, index) => ({...approver, ordinalNumber: index + 1}));
