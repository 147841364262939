import {NxSelectOption} from '@nextbank/ui-components';
import {ReactNode} from 'react';
import {DraggableCheckItem} from '../../../../shared/model/check.model';

export enum DataTestIds {
  AGE_INPUT = 'AGE_INPUT',
  INCOME_INPUT = 'INCOME_INPUT',
  CRITERION_TYPE_SELECT = 'CRITERION_TYPE_SELECT',
  PROOF_TYPE_SELECT = 'PROOF_TYPE_SELECT',
  PROVINCE_SELECT = 'PROVINCE_SELECT'
}

export interface CheckProps {
  check: DraggableCheckItem;
  setCheck: (check: DraggableCheckItem) => void;
  endButton: ReactNode;
  dictionaryId?: number;
  phaseId: number;
  provinceOptions?: NxSelectOption<number>[];
  handleAutomaticCheck?: boolean;
}
