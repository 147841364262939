import {NxSelectOption} from '@nextbank/ui-components';
import isNil from 'lodash/isNil';
import {SelectHelper} from '../../utils/select-helper';
import {DictionaryEntry} from '../model/dictionary.model';
import {LoanConfiguration} from '../model/loan-configuration.model';

const SINGLE_PAYMENT_ENTRY_CODE = 'SINGLE_PAYMENT';

type IsSinglePaymentCheck = (id?: string) => boolean;

export interface PaymentIntervalOptionsProps {
  paymentIntervalOptions?: NxSelectOption[];
  getIsSinglePaymentInterval: IsSinglePaymentCheck;
}

export default function usePaymentIntervalOptions(
  loanProduct: LoanConfiguration, entries?: DictionaryEntry[]
): PaymentIntervalOptionsProps {

  const singlePaymentEntryId = entries?.find(({code}): boolean => code === SINGLE_PAYMENT_ENTRY_CODE)?.id;

  const getIsSinglePaymentInterval = (singlePaymentEntryId?: number): IsSinglePaymentCheck =>
    (id?: string): boolean => !isNil(singlePaymentEntryId) && id === String(singlePaymentEntryId);

  const availableOptionsForConfiguration = entries?.filter(entry => loanProduct.paymentIntervals.includes(entry.code));

  return {
    paymentIntervalOptions: SelectHelper.mapToStringOptions(availableOptionsForConfiguration),
    getIsSinglePaymentInterval: getIsSinglePaymentInterval(singlePaymentEntryId)
  };
}

